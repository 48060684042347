import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Signal,
  contentChild,
  inject,
} from '@angular/core';
import { IwoInputDirective } from '../../directives/iwo-input.directive';
import { WLCM_INPUT_BINDER, WlcmFormFieldInput, WlcmFormsModule, WlcmSelectComponent } from '@wlcm/angular/forms';
import { IwoSelectDirective } from '../../directives/iwo-select.directive';
import { Observable, fromEvent, map, merge, BehaviorSubject, EMPTY, switchMap } from 'rxjs';

@Component({
  selector: 'napa-input-with-options',
  host: { class: 'napa-input-with-options' },
  standalone: true,
  imports: [CommonModule, WlcmFormsModule],
  templateUrl: './input-with-options.component.html',
  styleUrl: './input-with-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: WLCM_INPUT_BINDER,
      useFactory: () => {
        const input = inject(InputWithOptionsComponent, { self: true });

        return { bind: () => input };
      },
    },
  ],
})
export class InputWithOptionsComponent implements WlcmFormFieldInput, AfterContentInit {
  private readonly _focusStream$: BehaviorSubject<Observable<void>> = new BehaviorSubject(EMPTY as Observable<void>);

  private readonly _blurStream$: BehaviorSubject<Observable<void>> = new BehaviorSubject(EMPTY as Observable<void>);

  focus$: Observable<unknown> = this._focusStream$.pipe(switchMap((stream: Observable<unknown>) => stream));

  blur$: Observable<unknown> = this._blurStream$.pipe(switchMap((stream: Observable<unknown>) => stream));

  private _inputRef: Signal<ElementRef | undefined> = contentChild(IwoInputDirective, { read: ElementRef });

  private _selectComponent: Signal<WlcmSelectComponent | undefined> = contentChild(IwoSelectDirective, {
    read: WlcmSelectComponent,
  });

  ngAfterContentInit(): void {
    this.attachFocusBlurListeners();
  }

  isFocused(): boolean {
    return this.inputElement === document.activeElement || this.selectComponent.isOpen;
  }

  focus(): void {
    this.inputElement.focus();
  }

  get inputElement(): HTMLElement {
    return this._inputRef()!.nativeElement;
  }

  get selectComponent(): WlcmSelectComponent {
    return this._selectComponent()!;
  }

  private attachFocusBlurListeners(): void {
    const voidFn = () => {};

    const inputFocus: Observable<Event> = fromEvent(this.inputElement!, 'focus');

    const inputBlur: Observable<Event> = fromEvent(this.inputElement!, 'blur');

    const focusObservables: Observable<unknown>[] = [inputFocus, this.selectComponent.focus$];

    const blurObservables: Observable<unknown>[] = [inputBlur, this.selectComponent.blur$];

    this._focusStream$.next(merge(...focusObservables).pipe(map(voidFn)));

    this._blurStream$.next(merge(...blurObservables).pipe(map(voidFn)));
  }
}
