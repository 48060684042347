import {
  AbstractControl,
  FormControl,
  FormControlState,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { IwoFormModel } from '../models/input-with-options.models';
import { WlcmOption } from '@wlcm/angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */

export function createIwoFormGroup<T = any>(
  [value = '', optionValue = null]: [(FormControlState<T> | T | string)?, (WlcmOption['value'] | null)?],
  [formValidators, valueValidators, optionValidators]: [ValidatorFn[], ValidatorFn[], ValidatorFn[]]
): FormGroup<IwoFormModel> {
  return new FormGroup<IwoFormModel>(
    {
      value: new FormControl(value, [...valueValidators]),
      option: new FormControl(optionValue, [...optionValidators]),
    },
    [...formValidators, IwoFormValidator()]
  );
}

export function IwoFormValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup: FormGroup = control as FormGroup;

    let errors: ValidationErrors | null = {};

    for (const childControl of Object.values(formGroup.controls)) {
      errors = { ...errors, ...childControl.errors };
    }

    errors = Object.keys(errors).length > 0 ? errors : null;

    return errors;
  };
}
