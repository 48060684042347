import { FormControl, FormGroup, Validators } from '@angular/forms';
import { nameValidators } from '@core/constants/forms/form-validators.constants';
import { createIwoFormGroup } from '@shared/modules/input-with-options/utils/input-with-options';
import { CreateKidFormConfig, KidFormModel, KidFormValue } from '../models/kid-form.models';
import { Child, ChildPayload } from '@core/models/entities/child.models';
import { WlcmAutocompleteOption } from '@wlcm/angular/core';
import { parseISO } from 'date-fns';

const createDiagnosisControl = (): FormControl => {
  return new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100_000)]);
};

export const createKidForm = (
  config: CreateKidFormConfig = { withDiagnosis: false, mode: 'write' }
): FormGroup<KidFormModel> => {
  const form: FormGroup<KidFormModel> = new FormGroup<KidFormModel>({
    id: new FormControl(null),
    full_name: new FormControl('', [Validators.required, ...nameValidators]),
    birthdate: new FormControl('', [Validators.required]),
    height: createIwoFormGroup(['', 'ft'], [[Validators.required], [Validators.required], [Validators.required]]),
    weight: createIwoFormGroup(['', 'lb'], [[Validators.required], [Validators.required], [Validators.required]]),
  });

  if (config.withDiagnosis) form.addControl('diagnosis', createDiagnosisControl());

  if (config.mode === 'select') {
    form.controls.full_name.setValidators([Validators.required]);

    form.controls.birthdate.disable();
    form.controls.height.disable();
    form.controls.weight.disable();
  }

  return form;
};

export const patchSelectableKidForm = (
  form: FormGroup<KidFormModel>,
  kid: Child,
  patchFullName: boolean = true
): void => {
  if (patchFullName) {
    form.controls.full_name.setValue(new WlcmAutocompleteOption(kid._id, kid.full_name, kid.full_name, kid));
  }

  form.controls.birthdate.setValue(parseISO(kid.birthdate));

  form.controls.height.patchValue({ value: kid.height.toString(), option: kid.height_unit });

  form.controls.weight.patchValue({ value: kid.weight.toString(), option: kid.weight_unit });
};

export const transformToKidFormValue = (child: Child): KidFormValue => {
  const payload: Partial<KidFormValue> = {
    id: child._id,
    full_name: child.full_name,
    birthdate: parseISO(child.birthdate),
  };

  if (child.diagnosis) {
    payload.diagnosis = child.diagnosis;
  }

  payload.height = { value: child.height.toString(), option: child.height_unit };

  payload.weight = { value: child.weight.toString(), option: child.weight_unit };

  return payload as KidFormValue;
};

export const transformToChildPayload = (formPayload: KidFormValue): ChildPayload => {
  const payload: Partial<ChildPayload> = {
    id: formPayload.id,
    full_name: formPayload.full_name,
    birthdate: formPayload.birthdate?.toISOString?.(),
  };

  if (formPayload.diagnosis) {
    payload.diagnosis = formPayload.diagnosis;
  }

  payload.weight = Number(formPayload.weight.value);
  payload.weight_unit = formPayload.weight.option;

  payload.height = Number(formPayload.height.value);
  payload.height_unit = formPayload.height.option;

  return payload as ChildPayload;
};
