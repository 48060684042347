<div class="parent-form" [formGroup]="form">
  <div class="form-fields-group">
    @if (mode() === 'write') {
      <wlcm-form-field class="input-one">
        <wlcm-label>Client Name</wlcm-label>

        <input wlcmInput formControlName="full_name" placeholder="Type here" />
      </wlcm-form-field>
    } @else {
      <wlcm-form-field>
        <wlcm-label>Client Name</wlcm-label>

        <wlcm-select
          wlcmInput
          napaTrackReactiveControl
          placeholder="Not selected"
          formControlName="full_name"
          selectOptionFormat="CompleteOption"
          (selectionChange)="handleSelectionChange($event)"
          [options]="kidOptions()"
        ></wlcm-select>
      </wlcm-form-field>
    }
  </div>

  <div class="form-fields-group">
    <wlcm-form-field>
      <wlcm-label>Date of birth</wlcm-label>

      <ng-container
        [wlcmIcon]="WlcmIconName.CALENDAR"
        [wlcmIconStopPropagation]="true"
        (wlcmIconClicked)="picker.open()"
        wlcmFormFieldPrefix
      ></ng-container>

      <wlcm-datepicker-input-container wlcmInput #picker="wlcmDatepicker" [maxDate]="maxDate">
        <input formControlName="birthdate" placeholder="mmm/dd/yyyy" wlcmDatepickerInput />
      </wlcm-datepicker-input-container>
    </wlcm-form-field>
  </div>

  @if (includesDiagnosis) {
    <wlcm-form-field>
      <wlcm-label>Diagnosis</wlcm-label>

      <textarea wlcmInput formControlName="diagnosis" placeholder="Type here"></textarea>
    </wlcm-form-field>
  }

  <div class="form-fields-group unit-fields">
    <wlcm-form-field>
      <wlcm-label>Height</wlcm-label>

      <napa-input-with-unit wlcmInput formGroupName="height" [unitOptions]="heightOptions"></napa-input-with-unit>
    </wlcm-form-field>

    <wlcm-form-field>
      <wlcm-label>Weight</wlcm-label>

      <napa-input-with-unit wlcmInput formGroupName="weight" [unitOptions]="weightOptions"></napa-input-with-unit>
    </wlcm-form-field>
  </div>
</div>
