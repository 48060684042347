import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ParentFormComponent } from './components/parent-form/parent-form.component';
import { KidFormComponent } from './components/kid-form/kid-form.component';

const publicComponents: Type<unknown>[] = [ParentFormComponent, KidFormComponent];

@NgModule({
  imports: [CommonModule, ...publicComponents],
  exports: [...publicComponents],
})
export class FamilyFormsModule {}
