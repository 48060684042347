import {
  ChangeDetectorRef,
  Inject,
  Optional,
  Host,
  Self,
  Directive,
  OnInit,
  InputSignal,
  input,
  HostBinding,
  OutputEmitterRef,
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { WLCM_FORM_CONTROL, WlcmFormControl } from '@wlcm/angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NapaForm } from '@core/models/form.models';

export type FamilyMemberFormMode = 'select' | 'write';

export type FamilyMemberFormLayout = 'structured' | 'full-width';

@UntilDestroy()
@Directive()
export abstract class FamilyMemberForm<T extends { [K in keyof T]: AbstractControl }, P = unknown>
  extends NapaForm<P>
  implements OnInit
{
  mode: InputSignal<FamilyMemberFormMode> = input<FamilyMemberFormMode>('write');

  layout: InputSignal<FamilyMemberFormLayout> = input<FamilyMemberFormLayout>('structured');

  abstract selected: OutputEmitterRef<unknown>;

  @HostBinding('class') get modeClass() {
    return `mode-${this.mode()}`;
  }

  @HostBinding('class') get layoutClass() {
    return `layout-${this.layout()}`;
  }

  protected form!: FormGroup<T>;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    @Inject(WLCM_FORM_CONTROL) protected wlcmFormControl: WlcmFormControl,
    @Optional() @Host() @Self() protected controlContainer: ControlContainer
  ) {
    super();
  }

  ngOnInit(): void {
    this.validateControlContainer(this.controlContainer);

    this.form = this.controlContainer.control as FormGroup;

    if (this.wlcmFormControl) {
      this.wlcmFormControl.stateChanges$.pipe(untilDestroyed(this)).subscribe(() => this.changeDetector.markForCheck());
    }
  }

  abstract validateControlContainer(controlContainer: ControlContainer): void;
}
