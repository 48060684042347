import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  inject,
  input,
  InputSignal,
  Optional,
  Self,
  Signal,
  viewChild,
} from '@angular/core';
import { InputWithOptionsModule } from '@shared/modules/input-with-options/input-with-options.module';
import { WLCM_INPUT, WLCM_INPUT_BINDER, WlcmFormsModule, WlcmInputGetter } from '@wlcm/angular/forms';
import { IwoInputDirective } from '@shared/modules/input-with-options/directives/iwo-input.directive';
import { IwoFormModel } from '@shared/modules/input-with-options/models/input-with-options.models';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WlcmOption } from '@wlcm/angular/core';
import { IMaskModule } from 'angular-imask';

@Component({
  selector: 'napa-input-with-unit',
  standalone: true,
  imports: [CommonModule, InputWithOptionsModule, ReactiveFormsModule, WlcmFormsModule, IMaskModule],
  templateUrl: './input-with-unit.component.html',
  styleUrl: './input-with-unit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: WLCM_INPUT_BINDER,
      useFactory: () => {
        const input = inject(InputWithUnitComponent, { self: true });

        return { bind: () => input.wlcmInput()?.get() };
      },
    },
  ],
})
export class InputWithUnitComponent {
  wlcmInput: Signal<WlcmInputGetter | undefined> = viewChild(WLCM_INPUT);

  inputRef: Signal<ElementRef | undefined> = viewChild(IwoInputDirective, { read: ElementRef });

  unitOptions: InputSignal<WlcmOption[]> = input<WlcmOption[]>([]);

  maskOptions = { mask: Number, min: 0, scale: 3 };

  constructor(@Optional() @Host() @Self() private controlContainer: ControlContainer) {
    if (!this.controlContainer) throw new Error('FormGroup<IwoFormModel> is not provided.');
  }

  get form(): FormGroup<IwoFormModel> {
    return this.controlContainer.control as FormGroup<IwoFormModel>;
  }
}
