import { Directive, HostBinding } from '@angular/core';
import { WLCM_FORM_FIELD_INPUT_CLASS } from '@wlcm/angular/forms';

@Directive({
  selector: '[napaIwoInput]',
  standalone: true,
})
export class IwoInputDirective {
  @HostBinding('class') get wlcmInputClass() {
    return WLCM_FORM_FIELD_INPUT_CLASS;
  }
}
