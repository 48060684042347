import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWithOptionsComponent } from './components/input-with-options/input-with-options.component';
import { IwoSelectDirective } from './directives/iwo-select.directive';
import { IwoInputDirective } from './directives/iwo-input.directive';

const components: Type<unknown>[] = [InputWithOptionsComponent];

const directives: Type<unknown>[] = [IwoInputDirective, IwoSelectDirective];

@NgModule({
  imports: [CommonModule, ...components, ...directives],
  exports: [...components, ...directives],
})
export class InputWithOptionsModule {}
