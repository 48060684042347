import { Directive, effect, ElementRef, input, InputSignal } from '@angular/core';
import { Role } from '@core/constants';
import { ProfileApi } from '@core/services/api/profile.api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[napaCanRender]',
  standalone: true,
})
export class CanRenderDirective {
  roles: InputSignal<Role[]> = input.required({ alias: 'napaCanRender' });

  constructor(
    private element: ElementRef,
    private profileApi: ProfileApi
  ) {
    effect(() => {
      if (!this.roles().length) return;
      this.profileApi.user$.pipe(untilDestroyed(this)).subscribe((user) => {
        if (!this.roles().includes(user?.role as Role)) {
          this.element.nativeElement.style.display = 'none';
        }
      });
    });
  }
}
